'use client';

import React, { useEffect } from 'react';

import { getCards } from 'src/api/card';
import { getBasket } from 'src/api/basket';
import { useAuthContext } from 'src/auth/hooks';
import { useAppContext } from 'src/contexts/AppContext';
import { getActivityByDomain } from 'src/api/activities';

import { getVirtualCards } from '../../api/clubCards';
import { getAllUsers } from '../../api/user';
import { getKosher, getLocation } from '../../api/attributes';

const BasicDownload = () => {
  const { dispatch, state } = useAppContext();
  const { user } = useAuthContext();
  const activityId = state.smbActivityId || '';

  useEffect(() => {
    getKosher(dispatch);
    getLocation(dispatch);
  }, []);

  useEffect(() => {
    const getBasic = async () => {
      await getActivityByDomain(dispatch);

      if (user) {
        await getBasket(state.smbAccount.id, dispatch);
        await getAllUsers(dispatch);
      }
    };
    getBasic();
  }, [user]);

  useEffect(() => {
    const init = async () => {
      if (activityId) {
        await getVirtualCards(activityId, dispatch);
        await getCards(activityId, dispatch);
      }
    };
    init();
  }, [user, activityId]);

  return <div />;
};
export default BasicDownload;
